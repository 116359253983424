import React from 'react';
import propTypes from 'prop-types';

const BenefitItem = ({ title, description, src }) => (
  <div className="tw-col-span-3 md:tw-col-span-1 tw-flex md:tw-p-4 tw-py-4 tw-gap-4 tw-items-start">
    <img className="tw-mt-2" src={ src } alt={ title } width={ 40 } height={ 40 } />
    <div className="tw-flex tw-flex-col tw-items-start tw-text-left md:tw-justify-center tw-mb-4">
      <h3 className="tw-text-[18px] tw-font-semibold tw-mb-2 nudista-web">{ title }</h3>
      <p className="tw-text-[#D4D4D4] tw-leading-[22px]">{ description }</p>
    </div>
  </div>
);

const BenefitsHydroguard = () => {
  return (
    <div className="tw-bg-black tw-text-white tw-py-8 md:tw-py-20">
      <div className="product-feed !tw-mt-0 !tw-pt-0">
        <div className="tw-max-w-[400px] md:tw-m-auto">
          <h2 className="tw-mt-8 tw-text-white md:tw-text-center md:tw-text-5xl" data-cy="product-feed-title">
            Benefits of hydroguard?
          </h2>
          <p className="tw-mb-14 md:tw-text-center tw-mt-4 tw-text-balance">Trusted by top athletes for its innovative design and unmatched convenience.</p>
        </div>
        <div className="tw-grid tw-grid-cols-3 tw-gap-5 md:!tw-mt-16">
          <BenefitItem src="/assets/benefits/teeth.svg" title="" description="Effectively reduces the risk of dental fractures and safeguards against injuries to your lips, tongue, face, or jaw." />
          <BenefitItem src="/assets/benefits/swift.svg" title="" description="Provides convenient and swift access to hydration whenever you need it." />
          <BenefitItem src="/assets/benefits/tap.svg" title="" description="Enables more frequent hydration, effectively reducing the likelihood of dehydration." />
          <BenefitItem src="/assets/benefits/pain.svg" title="" description="Assists in mitigating heat-related injuries such as heat stroke and sprains." />
          <BenefitItem src="/assets/benefits/team.svg" title="" description="Gives your team a competetive edge." />
          <BenefitItem src="/assets/benefits/heat.svg" title="" description="Facilitates hydration during hot weather conditions while playing." />
        </div>
      </div>
    </div>
  );
};

const { string } = propTypes;

BenefitItem.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  src: string.isRequired
};

export default BenefitsHydroguard;
