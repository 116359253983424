import React from 'react';
import propTypes from 'prop-types';

const BannerFeature = (props) => {
  const {
    heading,
    subheading,
    btnText,
    href,
    bgSrc,
    className,
    textColor
  } = props;

  return (
    <div
      style={ {
        backgroundImage: `url('${bgSrc}')`,
        backdropFilter: 'brightness(0.5)',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      } }
      className={ `tw-py-16 md:tw-py-28 tw-relative tw-overflow-hidden tw-text-black ${ className }` }
    >
      <div className="tw-grid tw-grid-cols-2 tw-gap-5 !tw-mt-0 product-feed tw-z-10 tw-relative">
        <div className="tw-max-w-[400px]">
          <a href={ href } target="" className={ textColor } rel="noreferrer">
            <div className="tw-flex tw-gap-6 tw-flex-col">
              <h3 className="tw-italic md:tw-text-[48px] tw-text-[32px] tw-leading-[80%]">{ heading }</h3>
              <p className="nudista-web tw-uppercase md:tw-text-[18px] tw-leading-[130%]">{ subheading }</p>
              <a href={ href } target="" rel="noreferrer" className="pr_btn !tw-border-none !tw-w-fit tw-uppercase tw-h-[48px] tw-px-[20px]" style={ { borderRadius: '0', backgroundColor: 'rgb(0, 0, 0)', color: 'rgb(255, 255, 255)' } }>{ btnText }</a>
            </div>
          </a>
        </div>
      </div>
      <div className="tw-absolute tw-w-full tw-h-full max-md:tw-bg-[#ffffff82] tw-top-0 tw-z-0" />
    </div>
  );
};

const { string } = propTypes;

BannerFeature.propTypes = {
  className: string.isRequired,
  href: string,
  heading: string.isRequired,
  subheading: string.isRequired,
  btnText: string.isRequired,
  bgSrc: string.isRequired,
  textColor: string.isRequired
};

// default props
BannerFeature.defaultProps = {
  href: ''
};

export default BannerFeature;
