import React, { useEffect, Fragment } from 'react';
import { manageZendeskVisibility } from 'utils/zendeskUtils';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { bpProps } from 'utils/responsiveUtils';
import BenefitsHydroguard from 'components/BenefitsHydroguard';
import BannerFeature from 'components/BannerFeature';
import ListingPageWrapper from './ListingPageWrapper';

const ListingPage = (props) => {
  const {
    listingDetails,
    listingGallery,
    relatedProducts,
    privateListing,
    setClass
  } = props;

  const productSku = listingDetails?.props?.listing?.primaryProductSku;
  const { bpIsGT } = useSelector(state => ({ ...bpProps(state) }));
  useEffect(() => {
    manageZendeskVisibility(false);
  }, [bpIsGT('mobileLg')]);
  return (
    <Fragment>
      <div className="private-listing">
        { privateListing }
      </div>
      <div className="listing-content">
        { listingGallery }

        <div className={ setClass({ default: 'col--5', desktopSm: 'col--6', tabletSm: ' ' }) }>
          { listingDetails }
        </div>
      </div>
      {/* Guard on it own */}
      { productSku === '4455823803935_2436_106858_7972' && (
        <div className="benefits-hydroguard tw-mb-[48px] tw-flex tw-flex-col tw-gap-4">
          <BannerFeature
            bgSrc="/assets/bundle-bg.jpg"
            heading="Mouthguard kit"
            subheading="comfort and protection, with in-game hydration"
            btnText="Get the kit"
            href="/listing/new-defender5-gaurd-1reservoi"
            className="md:!tw-text-white !tw-bg-bottom tw-overflow-hidden tw-rounded-[12px]"
            textColor="!tw-text-white"
          />
          <div className="tw-overflow-hidden tw-rounded-[12px] tw-bg-black max-md:tw-px-[12px]">
            <BenefitsHydroguard />
          </div>
        </div>
      )}
      {/* Kit */}
      { productSku === '4455824309423_2437_106859_7973' && (
        <div className="benefits-hydroguard tw-mb-6 tw-mb-[48px] tw-overflow-hidden tw-rounded-[12px] tw-bg-black max-md:tw-px-[12px]">
          <BenefitsHydroguard />
        </div>
      )}
      { relatedProducts }
    </Fragment>
  );
};

ListingPage.defaultProps = {};
const { node, func } = propTypes;
ListingPage.propTypes = {
  listingDetails: node.isRequired,
  listingGallery: node.isRequired,
  relatedProducts: node.isRequired,
  privateListing: node.isRequired,
  setClass: func.isRequired
};

export default ListingPageWrapper(ListingPage);
