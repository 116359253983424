/* eslint-disable import/no-cycle, camelcase */
import get from 'lodash/get';
import has from 'lodash/has';
import fetch from 'cross-fetch';
import { API_HOST, COLLECTIONS_API_PATH, USE_COLLECTIONS } from '../../constants';

export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
export const RECEIVE_PRODUCT_CACHE = 'REQUEST_PRODUCT_CACHE';
export const RECEIVE_PRODUCT_BLANKS = 'RECEIVE_PRODUCT_BLANKS';
export const FETCH_PRODUCT_BLANK = 'FETCH_PRODUCT_BLANK';
export const DISABLE_PRODUCT_CACHE = 'DISABLE_PRODUCT_CACHE';
const IS_FETCHING_DATA = 'IS_FETCHING_DATA';

const baseCachePath = 'https://premium-storefronts.s3.amazonaws.com/product-data-cache/';

/**
 * Get product blank
 * @param  {Object}   product      Object representing payload
 * @return {function}          The dispatch function
 */

export const fetchProductBlank = product => async (dispatch) => {
  try {
    if (!product) {
      return;
    }

    const productId = has(product, 'primaryProductId') ?
      product.primaryProductId :
      product.productId;

    const url = `${API_HOST}/v1/blanks?id=${productId}`;
    const res = await fetch(url);
    const data = await res.json();

    dispatch({
      type: FETCH_PRODUCT_BLANK,
      data,
      productId
    });
  } catch (err) {
    throw err;
  }
};

export const disableProductCache = () => ({
  type: DISABLE_PRODUCT_CACHE
});

/**
 * Starting product request
 * @param  {Boolean} lazyLoad Bool to decide if this should load in the background or not
 * @return {function}         The dispatch function
 */
export const requestProducts = (lazyLoad = false) => (dispatch) => {
  const isFetchingData = !lazyLoad;
  dispatch({
    type: IS_FETCHING_DATA,
    isFetchingData
  });
};

/**
 * Finish product request and mark as received
 * @param  {object} storeProducts     The products of a store returned from the Commerce API
 * @return {[type]}          [description]
 */
export const receiveProducts = storeProducts => (dispatch) => {
  dispatch({
    type: IS_FETCHING_DATA,
    isFetchingData: false
  });
  dispatch({
    type: RECEIVE_PRODUCTS,
    storeProducts
  });
};

/**
 * Finish request and mark product data as received
 * @param  {bool} loaded True once data has loaded
 * @return {function}          Dispatch function
 */
export const productCacheLoaded = (loaded = true) => (dispatch) => {
  dispatch({
    type: IS_FETCHING_DATA,
    isFetchingData: false
  });
  dispatch({
    type: RECEIVE_PRODUCT_CACHE,
    productCacheLoaded: loaded
  });
};

/**
 * Finish product blanks request and mark as received
 * @param  {string}   listingSlug The slug of the store from teespring.com
 * @param  {string}   productId The id of the product
 * @param  {object}   data      The data returned from the store API
 * @return {Function}           Dispatch function
 */
export const receiveProductBlanks = (listingSlug, productId, data) => (
  dispatch
) => {
  dispatch({
    type: RECEIVE_PRODUCT_BLANKS,
    productBlanks: data,
    listingSlug,
    productId
  });
};

/**
 * Get product blanks
 * @param  {string}   listingSlug The slug of the listing from teespring.com
 * @param  {Object}   data      Object representing payload
 * @return {function}          The dispatch function
 */

export const fetchProductBlanks = data => async (dispatch, getState) => {
  try {
    if (!data?.products_payload) {
      return;
    }
    const { productDetails } = getState().storeListings;

    const promises = data.products_payload.reduce((collector, product) => {
      const productId = get(product, 'productId');
      if (!productDetails[productId]) {
        collector.push(dispatch(fetchProductBlank(product)));
      }
      return collector;
    }, []);

    await Promise.all(promises);
  } catch (err) {
    throw err;
  }
};

/**
 * Get product data
 * @param  {Number}   page              The page number to fetch
 * @return {function}                   The dispatch function
 */
export function fetchProducts(page) {
  return async (dispatch, getState) => {
    try {
      dispatch(requestProducts());
      const { localizationData, stores } = getState();
      const { previewMode } = getState().themeData;
      const { buyer_region, buyer_currency } = localizationData;
      const { slug } = stores;

      let url = '';
      if (USE_COLLECTIONS) {
        const collectionsRes = await fetch(`${COLLECTIONS_API_PATH}/collections?storeSlug=${slug}`);
        const { collections } = await collectionsRes.json();
        const collectionId = collections[0].id;
        url = `${COLLECTIONS_API_PATH}/collection/${collectionId}/items?page=${page}`;
      } else {
        url = `${API_HOST}/v1/stores/products?slug=${slug}&currency=${buyer_currency}&region=${buyer_region}&per=20&page=${page}&visibility=any`;
      }

      if (previewMode) url += `&visibility=any`;
      const res = await fetch(url, { headers: { Accept: 'application/json' } });
      if (!res.ok) throw new Error(`Failed to fetch products`);
      const data = await res.json();
      if (USE_COLLECTIONS) {
        dispatch(receiveProducts(data.items));
      } else {
        dispatch(receiveProducts(data));
      }
    } catch (err) {
      throw err;
    }
  };
}

/**
 * Get product data
 * @param  {Number}   page              The page number to fetch
 * @return {function}                   The dispatch function
 */
export const fetchProductCache = page => async (dispatch, getState) => {
  const { slug } = getState().stores;

  try {
    dispatch(requestProducts(slug));
    const dataUrl = `${baseCachePath}${slug}.json`;

    const res = await fetch(dataUrl, { headers: { Accept: 'application/json' } });
    if (!res.ok) throw new Error(`Failed to fetch products`);

    const data = await res.json();
    dispatch(productCacheLoaded());
    dispatch(receiveProducts(data));
  } catch (err) {
    dispatch(fetchProducts(page));
    dispatch(disableProductCache());
  }
};
