/*eslint-disable no-shadow */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { Fragment, useEffect } from 'react';
import Pagination from 'components/Pagination';
import get from 'lodash/get';
import { Button, Skeleton } from '@springforcreators/propel-ui';
import ProductTile from 'components/ProductTile';
import BannerFeature from 'components/BannerFeature';
import BenefitsHydroguard from 'components/BenefitsHydroguard';
import ThemeWrapper from 'containers/ThemeWrapper';
import { Waypoint } from 'react-waypoint';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { useGlobalProps } from 'containers/GlobalPropsContext';
import tracker from 'utils/tracking';
import { PRODUCT_IMPRESSIONS, pushEvent } from '../../utils/tracking/gtm';
import removeCurrencySymbol from '../../lib';
import { USE_COLLECTIONS } from '../../constants';
import { ProductTileCollections } from '../ProductTileCollections';
import './ProductFeed.scss';
import ProductFeedSkeleton from './ProductFeedSkeleton';

const ProductFeed = (props) => {
  const {
    storeProducts,
    isFetching,
    storeData,
    fetchProducts,
    themeData,
    getStyles
  } = useGlobalProps();

  const {
    title,
    localizationData,
    storeName,
    previewMode,
    storeListings
  } = props;

  const { layout } = themeData;

  const {
    products,
    page,
    totalPages,
    next
  } = storeProducts;
  const perPage = storeProducts.per_page;
  const showMoreProducts = USE_COLLECTIONS ? page !== totalPages : !!next;
  const { productCacheEnabled } = storeListings;
  const currentPage = page;

  let tiles = [];

  if (products && products.length) {
    tiles = products.map((product, position) => {
      if (USE_COLLECTIONS) {
        return <ProductTileCollections product={ product } key={ product.id } list="Featured Products" position={ position } />;
      } else {
        return <ProductTile product={ product } key={ product.id } list="Featured Products" position={ position } />;
      }
    });
  }

  const handleLoadMore = () => {
    tracker.track(`homepage.load_more.clicked`, { productCount: products.length });
    fetchProducts(currentPage + 1);
  };

  useEffect(() => {
    if (products.length) {
      const latestFetchedProducts = products.slice(products.length - perPage || 0, products.length);
      const productImpressions = latestFetchedProducts.map((product, position) => (
        {
          name: get(product, 'name'),
          id: get(product, 'listingId'),
          price: removeCurrencySymbol(get(product, 'price')),
          brand: storeName,
          category: get(product, 'productName'),
          list: 'Featured Products',
          position: position + 1,
          dimension8: get(storeData, 'sellerId'),
          dimension9: get(product, 'listingId')
        }
      ));
      const eventBody = {
        currencyCode: get(localizationData, 'buyer_currency'),
        impressions: productImpressions
      };
      pushEvent(PRODUCT_IMPRESSIONS, eventBody);
    }
  }, [products]);

  const Video = ({ src }) => (
    <div className="tw-col-span-3 md:tw-col-span-1 tw-bg-black tw-w-full tw-rounded-lg tw-overflow-hidden">
      <video controls="true" className="tw-w-full">
        <source src={ src } type="video/mp4" />
      </video>
    </div>
  );

  return (
    <Fragment>
      <div className="anchor" id="featured-products" />
      <div className="product-feed !tw-py-8 md:!tw-py-16">
        <div className="tile-heading tw-flex tw-flex-col !tw-text-left">
          {isFetching && !products?.length ? (
            <Skeleton styles={ { height: 30, width: '45%' } } />
          ) : (
            <>
              <h2 className="md:tw-text-5xl" style={ getStyles('productList.titleStyles') } data-cy="product-feed-title">
                {title}
              </h2>
              <p className="tw-mt-4">Pre-season may be over but our limited time pre-order pricing is still going on!</p>
            </>
          )}
        </div>

        { isFetching && !products?.length && (
          <ProductFeedSkeleton />
        ) }

        { (previewMode || get(layout, 'productList.pagination'))
          ? <Pagination items={ tiles } />
          : <div className="product-tile-grid" data-cy="product-tile-grid">{ tiles }</div>
        }

        { showMoreProducts && !productCacheEnabled && (
          <>
            { currentPage < 2 ? (
              <Waypoint
                onEnter={
                  (wp) => {
                    if (!isFetching && wp.previousPosition === 'below' && !get(layout, 'productList.pagination')) {
                      const { page } = storeProducts;
                      fetchProducts(page + 1);
                    }
                  }
                }
              />
            ) : (
              <div className="center-content mb4">
                { !isFetching && (
                  <Button
                    onClick={ handleLoadMore }
                  >
                    Load More
                  </Button>
                )}
              </div>
            )}

          </>
        ) }
      </div>
      <BannerFeature
        bgSrc="/assets/bundle-bg.jpg"
        heading="Mouthguard kit"
        subheading="comfort and protection, with in-game hydration"
        btnText="Get the kit"
        href="/listing/new-defender5-gaurd-1reservoi"
        className="md:!tw-text-white !tw-bg-bottom"
      />
      <BenefitsHydroguard />
      <div className="product-feed !tw-py-8 md:!tw-py-16">
        <h2 className="tw-mt-8 tw-text-black md:tw-text-5xl" style={ getStyles('productList.titleStyles') } data-cy="product-feed-title">
          Loved by athletes
        </h2>
        <p className="tw-mb-14 tw-mt-4">Trusted by athletes for its innovative design and unmatched convenience.</p>
        <div className="tw-grid tw-grid-cols-3 tw-gap-5">
          <Video src="/assets/vid1_web.mp4" />
          <Video src="/assets/vid2_web.mp4" />
          <Video src="/assets/vid3_web.mp4" />
        </div>
      </div>
    </Fragment>
  );
};

const { string, shape, bool } = propTypes;

ProductFeed.propTypes = {
  title: string,
  description: string,
  localizationData: shape({}).isRequired,
  storeName: string,
  previewMode: bool.isRequired,
  storeListings: shape({}).isRequired,
  src: string
};

ProductFeed.defaultProps = {
  title: null,
  description: null,
  storeName: '',
  src: ''
};

const mapStateToProps = state => ({
  localizationData: state.localizationData,
  storeName: state.stores.name,
  previewMode: get(state, 'themeData.previewMode'),
  storeListings: get(state, 'storeListings')
});

export default connect(mapStateToProps)(ThemeWrapper(ProductFeed, ['content.productList', 'styles.productList', 'layout.productList']));
