import React, { forwardRef } from 'react';
import propTypes from 'prop-types';
import get from 'lodash/get';
import Price from 'components/Price';
import './VariantSelector.scss';

const VariantSelector = forwardRef((props, ref) => {
  const {
    items,
    type,
    activeIndex,
    onChange,
    activeStyles
  } = props;

  return (
    <div ref={ ref } className={ `variantselector__grid ${type} ${items.length < 6 ? '' : ''}` }>
      { items.map((item, index) => {
        const isChecked = index === activeIndex;
        const slug = item.label.toLowerCase().replace(' ', '-');
        const highlightStyles = isChecked ? activeStyles : {};
        const { borderColor, ...highLightStylesWithoutBorderColor } = { ...highlightStyles };
        const styles = type === 'color' ? {
          backgroundColor: item.hex,
          ...highLightStylesWithoutBorderColor
        } : {
          backgroundColor: item.hex,
          borderColor,
          ...highLightStylesWithoutBorderColor
        };

        return (
          <div
            key={ item.id }
            className={ `variantselector__item ${isChecked ? 'is-active' : ''} ${ item.disabled ? 'is-disabled' : '' }` }
          >
            <label
              className="variantselector__label"
              htmlFor={ slug }
            >
              { type === 'color' && (
                <div
                  className="variantselector__color"
                  style={ styles }
                />
              ) }

              { type === 'size' && (
                <div
                  className="variantselector__size"
                  style={ { ...highlightStyles } }
                >
                  { item.label }
                </div>
              ) }

              { type === 'product' && (
                <div className="variantselector__product">
                  <div
                    className="variantselector__product__img"
                    style={ { ...highlightStyles } }
                  >
                    <img src={ get(item, 'thumb.src') } alt={ item.label } />
                  </div>

                  <div className="variantselector__product__info">
                    <span>{ item.label }</span>
                    <strong><Price value={ item.price } /></strong>
                  </div>
                </div>
              ) }

              <input
                type="radio"
                id={ slug }
                name="variantselector__radio"
                value={ index }
                checked={ isChecked }
                onChange={ () => onChange instanceof Function && onChange(index) }
                data-cy={ `variant-selector-${type}-${index}` }
                data-testid={ `variant-selector-${type}-${index}` }
              />
            </label>

            { item.tooltip && (
              <div className="variantselector__tooltip">
                { item.tooltip }
              </div>
            ) }
          </div>
        );
      }) }

    </div>
  );
});
const {
  arrayOf,
  string,
  shape,
  number,
  func,
  oneOf
} = propTypes;

VariantSelector.propTypes = {
  items: arrayOf(shape({
    id: number,
    label: string,
    hex: string,
    thumb: shape({ src: string }),
    tooltip: string
  })).isRequired,
  type: oneOf(['color', 'size', 'product']).isRequired,
  activeIndex: number.isRequired,
  onChange: func.isRequired,
  activeStyles: shape({})
};

VariantSelector.defaultProps = {
  activeStyles: {}
};

export default VariantSelector;
